import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import UploadFieldMui from "../FileUpload/UploadFieldMui";
import { useAlbum } from "../services/queries";
import AlbumImages from "./AlbumImages";
import { DownloadButtons } from "./DownloadButtons";
import { setPageTitle } from "../base/App";

export default function AlbumView() {
  let { albumId } = useParams();
  const albumQuery = useAlbum(albumId);

  const { isFetched, data: album } = albumQuery;
  console.log("Fetching Album", albumQuery, album, albumId);

  useEffect(() => {
    if (album) {
      setPageTitle(`${album.name} - ${album.creatorName}`);
    } else {
      setPageTitle("Album...");
    }
  }, [album]);

  if (!isFetched) {
    return <>Loading ...</>;
  }

  let { name, creatorName, uploads } = album;
  if (albumId != album.albumId) {
    uploads = [];
  }

  return (
    <div>
      <Box component="h2" sx={{ m: 1 }}>
        {name} - {creatorName}
      </Box>
      <UploadFieldMui album={album} />
      <AlbumImages albumId={albumId} images={uploads} />
      <DownloadButtons album={album} />
    </div>
  );
}
