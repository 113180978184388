import "./loading.scss";

export default function DropboxIcon({ rotating }) {
  return (
    <svg
      viewBox="0 0 30 25"
      fill="none"
      width="30"
      height="25"
      className={rotating ? "turningIcon" : ""}
    >
      <path
        d="M7.70076 0.320312L0.478516 4.91332L7.70076 9.50633L14.9242 4.91332L22.1465 9.50633L29.3687 4.91332L22.1465 0.320312L14.9242 4.91332L7.70076 0.320312Z"
        fill="#F7F5F2"
      ></path>
      <path
        d="M7.70076 18.6925L0.478516 14.0994L7.70076 9.50633L14.9242 14.0994L7.70076 18.6925Z"
        fill="#F7F5F2"
      ></path>
      <path
        d="M14.9242 14.0994L22.1465 9.50633L29.3687 14.0994L22.1465 18.6925L14.9242 14.0994Z"
        fill="#F7F5F2"
      ></path>
      <path
        d="M14.9242 24.8164L7.70077 20.2234L14.9242 15.6304L22.1465 20.2234L14.9242 24.8164Z"
        fill="#F7F5F2"
      ></path>
    </svg>
  );
}
