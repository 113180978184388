import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import {
  album,
  checkAuth,
  cloudUpload,
  createAlbum,
  favoriteImage,
  login,
  logout,
  uploadImage
} from "./api";
import {
  doOauth2,
  logoutOauth2
} from "./oauth2";

export const queryClient = new QueryClient();

export const useLogin = () => {
  return useMutation({
    mutationKey: ["login", "user"],
    mutationFn: login,
    onSuccess: (user) => queryClient.setQueryData(["user"], user),
  });
};

export const useLoginUser = () =>
  useQuery({
    queryKey: ["user"],
    queryFn: checkAuth,
    staleTime: 10 * 60 * 1000,
  });

const deleteCookie = (name) =>
  (document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT");

export const useExecLogout = () => {
  return useMutation({
    mutationKey: ["logout"],
    mutationFn: logout,
    onSettled: async () => {
      console.log("Settled Logout!");
      deleteCookie("JSESSIONID");
      await queryClient.invalidateQueries(["user"]);
    },
  });
};

export const useAlbum = (albumId) =>
  useQuery({
    queryKey: ["album", albumId],
    queryFn: () => album(albumId),
  });

export const useCreateAlbum = () =>
  useMutation({
    mutationKey: ["album"],
    mutationFn: createAlbum,
    onSuccess: (album) =>
      queryClient.setQueryData(["album", album.albumId], album),
  });

export const useUploadeImage = (albumId) =>
  useMutation({
    mutationKey: ["image"],
    mutationFn: uploadImage,
    onSettled: () => queryClient.invalidateQueries(["album", albumId]),
  });

export const useCloudUpload = (albumId, provider) =>
  useMutation({
    mutationKey: ["cloudupload", provider, albumId],
    mutationFn: cloudUpload(provider),
    onError: () => queryClient.invalidateQueries(["user"]),
  });

export const useOauth2 = (provider) =>
  useMutation({
    mutationKey: ["oauth2", provider],
    mutationFn: () => doOauth2(provider),
    onSettled: () => queryClient.invalidateQueries(["user"]),
  });

export const useLogoutOauth2 = (provider) =>
  useMutation({
    mutationKey: ["oauth2logout", provider],
    mutationFn: () => logoutOauth2(provider),
    onSettled: () => queryClient.invalidateQueries(["user"]),
  });


export const useFavoriteImage = (albumId) =>
  useMutation({
    mutationKey: ["favorite"],
    mutationFn: favoriteImage,
    //onSuccess: () => queryClient.invalidateQueries(["album", albumId]),
    onSuccess: (album) =>
      queryClient.setQueryData(["album", album.albumId], album),
  });
