import DownloadIcon from "@mui/icons-material/Download";
import { Button, FormControlLabel, Paper, Switch } from "@mui/material";
import React, { useState } from "react";
import GoogleIcon from "../other/GoogleIcon";
import { useCloudUpload, useLoginUser, useOauth2 } from "../services/queries";
import DropboxIcon from "../other/DropboxIcon";
import FacebookIcon from "../other/FacebookIcon";

function CloudProvider({ provider, Icon, name, isAll, albumId }) {
  const { data: user } = useLoginUser();
  const upload = useCloudUpload(albumId, provider);
  const doOauth = useOauth2(provider);

  let hasConnection = user?.cloudConnections?.find(
    (uc) => uc.cloudName == provider
  );

  return (
    <>
      {hasConnection && (
        <p>
          <Button
            onClick={() => upload.mutate({ albumId, isAll })}
            disabled={!hasConnection || upload.isPending}
            variant="contained"
            startIcon={<Icon rotating={upload.isPending} />}
          >
            Upload to {name} Photos
          </Button>
        </p>
      )}
      {!hasConnection && (
        <p>
          <Button
            onClick={doOauth.mutate}
            disabled={hasConnection || doOauth.isPending}
            variant="contained"
            color="info"
            startIcon={<Icon rotating={doOauth.isPending} />}
          >
            Connect to {name}
          </Button>
        </p>
      )}
    </>
  );
}

export function DownloadButtons({ album }) {
  let { albumId } = album;
  const [isAll, setIsAll] = useState(false);

  const downloadAllUrl = `/api/files/${albumId}/all${
    isAll ? "?allfiles=true" : ""
  }`;

  return (
    <Paper
      sx={{
        "& > :not(style)": {
          m: 1,
        },
      }}
      elevation={2}
    >
      <Button
        href={downloadAllUrl}
        download
        variant="contained"
        startIcon={<DownloadIcon />}
      >
        Download all
      </Button>
      <br />
      <CloudProvider
        albumId={albumId}
        isAll={isAll}
        provider="google"
        name="Google"
        Icon={GoogleIcon}
      />
      <CloudProvider
        albumId={albumId}
        isAll={isAll}
        provider="dropbox"
        name="Dropbox"
        Icon={DropboxIcon}
      />
      {/* <CloudProvider
        albumId={albumId}
        isAll={isAll}
        provider="facebook"
        name="Facebook"
        Icon={FacebookIcon}
      /> */}
      <p>
        Only favorites or:{" "}
        <FormControlLabel
          color="success"
          control={<Switch />}
          value={isAll}
          onChange={() => setIsAll(!isAll)}
          label="All"
        />
      </p>
    </Paper>
  );
}
