import {
  Badge,
  IconButton,
  ImageListItemBar,
  useMediaQuery,
} from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DownloadIcon from "@mui/icons-material/Download";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useTheme } from "@emotion/react";
import { downloadImageJs } from "../services/api";
import { useFavoriteImage } from "../services/queries";

const getImageUrl = (albumId, image) =>
  `/api/files/${albumId}/${image.fileName}`;

const ImageItem = ({ albumId, image }) => {
  const { mutate: setFav, isPending: isFavPending } = useFavoriteImage(albumId);
  const imgUrl = getImageUrl(albumId, image);
  const favCount = image?.rating?.favCount;
  const isFav = image?.rating?.favorite;

  const downloadImage = (e) => {
    console.log(imgUrl);
    e.preventDefault();
    downloadImageJs(albumId, image.fileName);
  };
  const switchFavorite = (e) => {
    setFav({
      albumId,
      uploadId: image.id,
      ratingRequest: { isFavorite: !isFav },
    });
  };

  return (
    <ImageListItem
      sx={{
        //mb: 1,
        width: "100%",
        height: "auto",
      }}
    >
      <img
        srcSet={imgUrl}
        src={imgUrl}
        alt={image.uploaderName}
        loading="lazy"
      />
      <ImageListItemBar
        sx={{
          background:
            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
            "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
        }}
        title={
          <>
            <IconButton
              onClick={switchFavorite}
              color={isFav ? "success" : "primary"}
              aria-label={`favorite ${image.uploaderName}`}
              disabled={isFavPending}
            >
              <Badge badgeContent={favCount} color="secondary">
                <FavoriteIcon />
              </Badge>
            </IconButton>
            {image.uploaderName}
          </>
        }
        position="bottom"
        actionIcon={
          <IconButton
            onClick={downloadImage}
            href={imgUrl}
            download
            color="primary"
            aria-label={`download ${image.uploaderName}`}
          >
            <DownloadIcon />
          </IconButton>
        }
        actionPosition="right"
      />
    </ImageListItem>
  );
};

const getGaleryColumns = () => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSM = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMD = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLG = useMediaQuery(theme.breakpoints.up("lg"));
  //const isXL = useMediaQuery(theme.breakpoints.up("xl"));

  // *    xs: 0,
  // *    sm: 600,
  // *    md: 900,
  // *    lg: 1200,
  // *    xl: 1536,
  if (isXS) return 1;
  if (isSM) return 2;
  if (isMD) return 3;
  if (isLG) return 4;
  return 6;
};
export default function AlbumImages({ albumId, images }) {
  const columns = getGaleryColumns();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("sm"));
  if (!images) {
    return <></>;
  }
  console.log("AlbumImages", albumId, images);
  return (
    <ImageList
      sx={{ width: "auto", height: 450, transform: "translateZ(0)" }}
      gap={1}
      cols={columns}
    >
      {images.map((image) => (
        <ImageItem key={image.id} albumId={albumId} image={image} />
      ))}
    </ImageList>
  );
}
