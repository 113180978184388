import { Alert, AlertTitle, Button, Link } from "@mui/material";
import { useLoginUser, useLogoutOauth2, useOauth2 } from "../services/queries";
import DropboxIcon from "./DropboxIcon";
import GoogleIcon from "./GoogleIcon";
import FacebookIcon from "./FacebookIcon";
import { useEffect } from "react";
import { setPageTitle } from "../base/App";

function CloudProvider({ provider, Icon, name }) {
  const { data: user } = useLoginUser();
  const logout = useLogoutOauth2(provider);
  const doOauth = useOauth2(provider);

  let hasConnection = user?.cloudConnections?.find(
    (uc) => uc.cloudName == provider
  );

  let doConnect = () => {
    if (user) {
      doOauth.mutate();
    } else {
      //login
      //connect
    }
  };

  return (
    <>
      {hasConnection && (
        <p>
          <Button
            onClick={logout.mutate}
            disabled={!hasConnection}
            variant="contained"
            color="error"
            startIcon={<Icon rotating={logout.isPending} />}
          >
            Disconnect from {name}
          </Button>
        </p>
      )}
      {user && !hasConnection && (
        <p>
          <Button
            onClick={doOauth.mutate}
            disabled={hasConnection || doOauth.isPending}
            variant="contained"
            color="info"
            startIcon={<Icon rotating={doOauth.isPending} />}
          >
            Connect to {name}
          </Button>
        </p>
      )}
    </>
  );
}

export default function About() {
  useEffect(() => {
      setPageTitle("About");
  }, []);
  return (
    <>
      <Alert variant="outlined" severity="info">
        <AlertTitle>
          Share your pictures on an independent platform keeping the high
          quality.
        </AlertTitle>
      </Alert>
      <p>
        This App is created by Alexis Suter, find me on Github:{" "}
        <Link
          href="https://github.com/EaseRider"
          target="_blank"
          rel="noreferrer"
        >
          https://github.com/EaseRider
        </Link>
      </p>
      <CloudProvider provider="google" name="Google" Icon={GoogleIcon} />
      <CloudProvider provider="dropbox" name="Dropbox" Icon={DropboxIcon} />
      {/* <CloudProvider provider="facebook" name="Facebook" Icon={FacebookIcon} /> */}

      <Alert variant="outlined" severity="info">
        <AlertTitle>Disclaimer</AlertTitle>
        Uploaded files are subject to be deleted after an undefined time. Files
        are not given to third parties and are only disposable to the ones
        having access to the album. Administrators keep the right on insight for
        search illegal contents. Illegal content and pornography is forbidden to
        be uploaded.
      </Alert>
      <p>
        <Link href="/static/policy.html">Pirvacy policy</Link>
      </p>
      <p>
        <Link href="/static/termsofuse_en.html">Terms of use</Link>
      </p>
    </>
  );
}
