/**
 *
 * @param {File} a
 * @param {File} b
 */
const isSameFile = (a, b) => {
  return (
    a.size === b.size && a.lastModified === b.lastModified && a.name === b.name
  );
};

const calculateHash = async (inFile) => {
  const file = inFile;
  if (file.sha1Sum) {
    return file.sha1Sum;
  }

  const arrayBuffer = await inFile.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest("SHA-1", arrayBuffer); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  file.sha1Sum = hashHex;
  return file.sha1Sum;
};

const getSha1 = (file) => {
  if (file.sha1Sum) {
    return file.sha1Sum;
  } else {
    return Math.random(); // No hashing available?
  }
};

const filterByHash = (fileList, album) => {
  let newFileList = fileList.filter((f, index) => {
    let sha1 = getSha1(f);
    return !fileList.some((cFile, cIndex) => {
      if (cFile == f || index < cIndex) {
        return false;
      }
      let cSha1 = getSha1(cFile);
      return cSha1 == sha1;
    });
  });
  newFileList = newFileList.filter((f) => {
    let sha1 = getSha1(f);
    return !album.uploads.some((upload) => upload.sha1Sum == sha1);
  });
  return newFileList;
};

export const filterFileSelection = async (files, fileList, album) => {
  // filter already chosen ones out
  let newFileList = fileList.filter(
    (f) => !files.some((e) => isSameFile(f, e))
  );

  newFileList.push(...files);

  for (const file of newFileList) {
    await calculateHash(file);
  }

  // filter Same Hash Images
  newFileList = filterByHash(newFileList, album);
  return newFileList;
};
